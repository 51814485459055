import React, { useEffect, useMemo, useState } from 'react';
import propTypes from 'prop-types';
import { graphql } from 'gatsby';

import Layout from '../components/Layouts/Layout';
import { TildaInit } from '../components/Tilda/TildaInit';
import { DesignPhotobookForm } from '../components/Forms/DesignPhotobook/DesignPhotobookForm';
import SEO from '../components/Layouts/SEO';
import styles from './tilda-page.module.scss';
import './tilda-page.scss';

let counter = 0;

const onLoad = (url, total, cb) => () => {
  counter += 1;
  if (counter === total) {
    cb();
  }
};

const publicAssetsLinks = (tildaAssetsArray, assetNodes) =>
  tildaAssetsArray
    .map((asset) => {
      const assetNode = assetNodes.find((n) => n.to === asset.to);
      if (!assetNode) {
        return null;
      }
      return assetNode.localFile && assetNode.localFile.publicURL
        ? assetNode.localFile.publicURL
        : null;
    })
    .filter((n) => n !== null);

/**
 *
 * @param {string} html TildaPage export html
 * @param {[object]} assetNodes Gatsby
 * @param {string} imgPath Tilda Export img prefix path, eg /assets/img/
 * @returns
 */
const replaceHtmlTildaAssetsToLocal = (html, assetNodes, imgPath) => {
  let result = html;
  assetNodes.forEach((n) => {
    const regExp = new RegExp(`${imgPath}${n.to}`, 'gi');
    if (n.localFile && n.localFile.publicURL) {
      result = result.replace(regExp, n.localFile.publicURL);
    }
  });
  return result;
};

export function Head({ data: { tildaPage } }) {
  return <SEO title={tildaPage.title} description={tildaPage.descr} image={tildaPage.img} />;
}

function TildaPage({
  data: {
    tildaPage,
    allTildaAsset: { nodes: assetNodes },
  },
}) {
  const [isLoading, setIsLoading] = useState(true);

  const cssLinks = useMemo(
    () => publicAssetsLinks(tildaPage.css, assetNodes),
    [assetNodes, tildaPage.css]
  );
  const jsLinks = useMemo(
    () => publicAssetsLinks(tildaPage.js, assetNodes),
    [tildaPage.js, assetNodes]
  );
  const html = useMemo(
    () => replaceHtmlTildaAssetsToLocal(tildaPage.html, assetNodes, tildaPage.export_imgpath),
    [assetNodes, tildaPage.export_imgpath, tildaPage.html]
  );

  useEffect(() => {
    counter = 0;
    const onLoaded = () => setIsLoading(false);
    const tildaPageElem = document.getElementById('tilda-page');

    jsLinks.forEach((link) => {
      const tag = document.createElement('script');
      tag.async = false;
      tag.defer = true;
      tag.src = link;
      tag.onload = onLoad(link, jsLinks.length, onLoaded);
      tildaPageElem.appendChild(tag);
    });
  }, [jsLinks]);

  const { initScripts, alias } = tildaPage;

  return (
    <Layout hasSubscriptionForm={!alias?.includes('subscription')}>
      {cssLinks.map((link) => (
        <link key={link} rel="stylesheet" type="text/css" href={link} />
      ))}

      <div className={styles.page} id="tilda-page" dangerouslySetInnerHTML={{ __html: html }} />
      {alias === 'design-photobook' && (
        <div className={styles.page} id="form">
          <DesignPhotobookForm />
        </div>
      )}
      <div id="tilda-scripts">{!isLoading && <TildaInit tildaInitScripts={initScripts} />}</div>
    </Layout>
  );
}

export default TildaPage;

TildaPage.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: propTypes.object.isRequired,
};

export const query = graphql`
  query ($pageId: String!) {
    tildaPage(pageId: { eq: $pageId }) {
      pageId
      alias
      initScripts
      title
      descr
      img
      export_imgpath
      css {
        from
        to
      }
      js {
        from
        to
      }
      images {
        from
        to
      }
      html
    }
    allTildaAsset(filter: { pageId: { eq: $pageId } }) {
      nodes {
        from
        to
        localFile {
          publicURL
        }
      }
    }
  }
`;
